import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_URL

export const accessBandAPI = {
    reportes: {
        async getReport(eventId) {
            return axios
                .get(`${baseUrl}/report`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
    },
    albumes: {
        async getAlbumes(eventId, page, limit) {
            const json = {
                where: {
                    eventId: eventId
                },
                offset: page,
                limit: limit,
                order: 'created DESC',
                include: [{
                    relation: 'comments',
                    scope: {
                        where: { visible: true }
                    }
                },
                {
                    relation: 'contents'
                }]
            }

            return axios
                .get(`${baseUrl}/albums?filter=` + JSON.stringify(json), {
                    headers: {
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data.filter(c => c.contents)
                })
        },
        async sendAlbum(album) {
            return axios
                .post(`${baseUrl}/albums`, album, {
                    headers: {
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async updateAlbum(album, id) {
            return axios
                .put(`${baseUrl}/albums/${id}`, album, {
                    headers: {
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async upload(eventId, albumId, file) {
            // Create an object of formData
            const formData = new FormData();

            // Update the formData object
            formData.append(
                "file",
                file
            );

            return axios({
                method: 'post',
                url: `${baseUrl}/upload/${eventId}/${albumId}`,
                data: formData,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                    'content-type': 'multipart/form-data',
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async removeImage(url) {
            let json = {
                url: url
            }
            return axios
                .post(`${baseUrl}/delete-product-image`, json, {
                    headers: {
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .catch(function (error) {
                    throw error.message
                })
        },
        async removeContent(albumId, contentId) {
            const json = { id: contentId }
            return axios
                .delete(`${baseUrl}/albums/${albumId}/contents?where=` + JSON.stringify(json), {
                    headers: {
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .catch(function (error) {
                    throw error.message
                })
        },
        async deleteAlbum(id) {
            return axios
                .delete(`${baseUrl}/albums/${id}`, {
                    headers: {
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .catch(function (error) {
                    throw error.message
                })
        }
    },
    users: {
        async changePassword(passwordData) {
            return axios({
                method: 'post',
                url: `${baseUrl}/users/changepassword`,
                data: { username: passwordData.username, oldpassword: passwordData.oldPassword, newpassword: passwordData.newPassword },
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async getAll(params) {
            let filter = {
                where:
                {
                    tenantId: localStorage.getItem('tenant-id')
                }
            }
            let mergedFilter = { ...params, ...filter }
            return axios({
                method: 'get',
                url: `${baseUrl}/users?filter=${JSON.stringify(mergedFilter)}`,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async create(user) {
            //assign only APP role in user creation 
            return axios({
                method: 'post',
                url: `${baseUrl}/signup`,
                data: user,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
    },
    events: {
        async createEvent(eventData) {
            return axios({
                method: 'post',
                url: `${baseUrl}/events`,
                data: eventData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async getEvents(params) {
            return axios({
                method: 'get',
                url: `${baseUrl}/events?filter=${JSON.stringify(params)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async getEventByCode(code) {
            return axios({
                method: 'get',
                url: `${baseUrl}/events/code/${code}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async updateEvent(eventToUpdate, id) {
            return axios({
                method: 'patch',
                data: eventToUpdate,
                url: `${baseUrl}/events/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async deleteEvent(id) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/events/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async getByEventCode(tenant, code) {
            return axios({
                method: 'get',
                url: `${baseUrl}/events/code/${tenant}_${code}`,
                headers: {
                    'x-tenant-id': `${tenant}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async batchDelete(events) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/events/batch-delete`,
                data: events,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async importMembers(members, eventId, remove) {
            return axios({
                method: 'post',
                data: members,
                url: `${baseUrl}/events/import-members/${eventId}?remove=${remove}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    members: {
        async create(data) {
            return axios({
                method: 'post',
                url: `${baseUrl}/members/add-member-to-event`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async update(id, data) {
            if (data.qrcode == null) data.qrcode = ''
            return axios({
                method: 'patch',
                url: `${baseUrl}/members/${id}`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async getMembersByEvent(eventId, params, onlyAlerts) {
            if (onlyAlerts === undefined) onlyAlerts = false
            return axios({
                method: 'get',
                url: `${baseUrl}/events/${eventId}/members?filter=${JSON.stringify(params)}&hasalert=${onlyAlerts}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async batchDelete(members) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/members/batch-delete`,
                data: members,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
    }, items: {
        async create(data) {
            return axios({
                method: 'post',
                url: `${baseUrl}/items`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async update(id, data) {
            return axios({
                method: 'put',
                url: `${baseUrl}/items/${id}`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async getItems(params) {
            return axios({
                method: 'get',
                url: `${baseUrl}/items?filter=${JSON.stringify(params)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async deleteItem(id) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/items/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async batchDelete(items) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/items/batch-delete`,
                data: items,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        }
    }, records: {
        async getAllRecords(params) {
            return axios({
                method: 'get',
                url: `${baseUrl}/record-bodies/panel?filter=${JSON.stringify(params)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async getRecordsByEvent(eventId) {
            return axios({
                method: 'get',
                url: `${baseUrl}/record-bodies/${eventId}/records`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async getRecordSetsByRecordBody(code) {
            return axios({
                method: 'get',
                url: `${baseUrl}/record-sets/${code}/records`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    albums: {
        async getByEvent(params) {
            return axios({
                method: 'get',
                url: `${baseUrl}/albums?filter=${JSON.stringify(params)}`,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
    },
    social: {
        async getMembers(id, params) {
            return axios({
                method: 'get',
                url: `${baseUrl}/social/events/${id}?filter=${JSON.stringify(params)}`,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    medicalRecord: {
        async uploadAttachMedicalRecord(medicalRecordId, file,tenant) {

            const formData = new FormData();
            formData.append("files", file);

            return axios({
                method: 'post',
                url: `${baseUrl}/upload-attach-medical-record/${medicalRecordId}`,
                data: formData,
                headers: {
                    'x-tenant-id': tenant,
                    'content-type': 'multipart/form-data',
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async getMedicalRecord(tenantId, contract, identification) {
            return axios({
                method: 'get',
                url: `${baseUrl}/medical-records/${contract}/${identification}`,
                headers: {
                    'x-tenant-id': `${tenantId}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async saveMedicalRecord(tenantId, medicalRecord) {
            return axios({
                method: 'post',
                url: `${baseUrl}/medical-records`,
                data: medicalRecord,
                headers: {
                    'x-tenant-id': `${tenantId}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async editMedicalRecord(tenantId, medicalRecord) {
            return axios({
                method: 'patch',
                url: `${baseUrl}/medical-records/${medicalRecord.id}`,
                data: medicalRecord,
                headers: {
                    'x-tenant-id': `${tenantId}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    memberMedicalRecord: {
        async getMedicalRecord(memberId) {
            return axios({
                method: 'get',
                url: `${baseUrl}/members/${memberId}/medical-record`,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    configuration: {
        async getConfigurations() {
            return axios({
                method: 'get',
                url: `${baseUrl}/configurations`,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async getPublicConfigurations(tenantId) {
            return axios({
                method: 'get',
                url: `${baseUrl}/configurations`,
                headers: {
                    'x-tenant-id': `${tenantId}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async saveConfigurations(configurationId, configuration) {
            return axios({
                method: 'put',
                url: `${baseUrl}/configurations/${configurationId}`,
                data: configuration,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    itinerary: {
        async getItinerariesByEvent(eventId, filter) {
            return axios({
                method: 'get',
                url: `${baseUrl}/events/${eventId}/itineraries/paginated?filter=${JSON.stringify(filter)}`,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async importItineraries(itineraries, eventId) {
            return axios({
                method: 'post',
                data: itineraries,
                url: `${baseUrl}/itineraries/import-itineraries/${eventId}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async updateItinerary(id, itineraryToUpdate, eventId) {
            const where = { id: id }
            return axios({
                method: 'patch',
                data: itineraryToUpdate,
                url: `${baseUrl}/events/${eventId}/itineraries?where=${JSON.stringify(where)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async deleteItinerary(id, eventId) {
            const where = { id: id }
            return axios({
                method: 'delete',
                url: `${baseUrl}/events/${eventId}/itineraries?where=${JSON.stringify(where)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async createItinerary(eventId, itinerary) {
            return axios({
                method: 'post',
                url: `${baseUrl}/events/${eventId}/itineraries`,
                data: itinerary,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async batchDelete(eventId, itineraries) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/events/${eventId}/itineraries/batch-delete`,
                data: itineraries,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
    },
    comments: {
        async getComments(filter) {
            return axios({
                method: 'get',
                url: `${baseUrl}/comments?filter=${JSON.stringify(filter)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async updateComment(id, comment) {
            return axios({
                method: 'patch',
                data: comment,
                url: `${baseUrl}/comments/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async batchDelete(comments) {
            return axios({
                method: 'delete',
                data: comments,
                url: `${baseUrl}/comments/batch-delete`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async batchProcess(mode, comments) {
            return axios({
                method: 'patch',
                data: comments,
                url: `${baseUrl}/comments/batch-process/${mode}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })

        },
        async deleteComment(id) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/comments/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        }
    },
    products: {
        async getProducts(filter) {
            return axios({
                method: 'get',
                url: `${baseUrl}/products?filter=${JSON.stringify(filter)}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
        async create(product) {
            return axios({
                method: 'post',
                url: `${baseUrl}/products`,
                data: product,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                if (error.response) {
                    throw error.response.data.error.message
                }
            })
        },
        async update(productToUpdate, id) {
            return axios({
                method: 'put',
                data: productToUpdate,
                url: `${baseUrl}/products/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async delete(id) {
            return axios({
                method: 'delete',
                url: `${baseUrl}/products/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async upload(productid, files) {

            const formData = new FormData();
            files.forEach(file => {
                formData.append("images", file);
            });

            return axios({
                method: 'post',
                url: `${baseUrl}/upload-product-image/${productid}`,
                data: formData,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                    'content-type': 'multipart/form-data',
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async removeFile(url) {
            return axios({
                method: 'post',
                url: `${baseUrl}/delete-product-image`,
                data: { url: url },
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async batchDelete(products) {
            return axios({
                method: 'delete',
                data: products,
                url: `${baseUrl}/products/batch-delete`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
    },
    messages: {
        async uploadAttach(messageId, file) {

            const formData = new FormData();
            formData.append("files", file);

            return axios({
                method: 'post',
                url: `${baseUrl}/upload-attach/${messageId}`,
                data: formData,
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                    'content-type': 'multipart/form-data',
                }
            }).catch(function (error) {
                throw error.message
            })
        },
        async getMessages(eventId) {
            let json = `{
                "where": {
                    "and":
                        [
                            {
                                "eventId": "${eventId}",
                                "membersTo": []
                            }
                        ]
                },
                "offset": 0,
                "limit": 99999,
                "order": "created DESC"
            }`

            return axios
                .get(`${baseUrl}/messages?filter=` + json, {
                    headers: {
                        //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async getMessages2(filter) {
            return axios
                .get(`${baseUrl}/messages?filter=` + filter, {
                    headers: {
                        //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async getMessagesPagination(filter) {
            return axios
                .get(`${baseUrl}/messages/pagination?filter=` + JSON.stringify(filter), {
                    headers: {
                        //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async getMessagesUsers(filter) {
            return axios
                .get(`${baseUrl}/messages/users?filter=` + JSON.stringify(filter), {
                    headers: {
                        //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async getMessagesMembers(messageId) {
            return axios
                .get(`${baseUrl}/messages/${messageId}/members`, {
                    headers: {
                        //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async removeMessage(messageId) {
            return axios
                .delete(`${baseUrl}/messages/${messageId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    },
                })
                .then(response => {
                    return response.data
                })
        },
        async sendMessages(eventCode, title, body, memberTo, file) {
            const message = {
                title: title,
                text: body,
                created: new Date(),
                eventId: eventCode,
                userfrom: undefined,
                //  userfrom: null,
                membersTo: memberTo.length > 0 ? memberTo : [],
            }
            let url = `${baseUrl}/messages`
            if (file) url = `${baseUrl}/messages?file=true`
            return axios
                .post(url, message, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                    }
                })
                .then(response => {
                    return response.data
                })
        },

    },
    files: {
        // URL es S3 path /tenant/idevento/codigo.zip
        async downloadZip(event) {
            const file = process.env.REACT_APP_AMZ_URL + '/'
                + localStorage.getItem('tenant-id')
                + '/' + event.id
                + '/' + event.code + '.zip'
            return axios.get(file, {
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },

        // folder: tenant/uuid del evento
        // zipname: codigo del evento
        async generateZip(folder, zipname) {

            return axios.post(`${baseUrl}/generateZip/`, { folder: folder, zipname: zipname }, {
                headers: {
                    'x-tenant-id': `${localStorage.getItem('tenant-id')}`,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }).catch(function (error) {
                console.log(error.toJSON())
            })
        },
    }
}
