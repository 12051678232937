import { Document, Page, Text, StyleSheet, View, Font, Image } from '@react-pdf/renderer'
import RobotoBlack from 'fonts/Roboto-Black.ttf'
import RobotoMedium from 'fonts/Roboto-Medium.ttf'
import Roboto from 'fonts/Roboto-Regular.ttf'
import RobotoLight from 'fonts/Roboto-Light.ttf'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser';
import assistcard from 'assets/images/assistcard.png'

Font.register({ family: 'Roboto Black', src: RobotoBlack })
Font.register({ family: 'Roboto Medium', src: RobotoMedium })
Font.register({ family: 'Roboto', src: Roboto })
Font.register({ family: 'Roboto Light', src: RobotoLight })

const htmlParser = (taskDescription) => {
    try {
        if (taskDescription !== 'undefined' && taskDescription !== undefined) {
            let returnContentConst = [];
            const parsedHtml = ReactHtmlParser(taskDescription);
            parsedHtml.forEach(element => {
                const type = element.type;

                element.props.children.forEach((content) => {
                    switch (type) {
                        case 'p':
                            returnContentConst.push(<Text style={{ family: 'Roboto', fontSize: 9, color: 'black' }}>{content}</Text>)
                            break;
                        case 'h3':
                            returnContentConst.push(<Text style={{ family: 'Roboto', textAlign: 'center', fontSize: 11, fontFamily: 'Roboto Black' }}>{content}</Text>)
                            returnContentConst.push(<Text style={{ family: 'Roboto', fontSize: 11, fontFamily: 'Roboto Black' }}> {'\n'} </Text>)

                            break;
                        default:
                            returnContentConst.push(<Text>{content}</Text>)
                            break;
                    }
                })
            })
            return (returnContentConst);
        } else {
            return (<></>);
        }
    } catch (error) {
        return (<></>);
    }
}

const styles = StyleSheet.create({
    dataMainContainer: {
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    dataContainer: {
        paddingBottom: '5px',
        color: '#333',
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 0,

    },
    dataExtraContainer: {
        paddingBottom: '5px',
        color: '#333',
        padding: 20,
    },
    row: {
        color: '#333',
        borderColor: '#999',
        padding: 5
    }

})

const getDate = (rawDate) => {
    return (
        moment(rawDate).format('DD/MM/YYYY')
    )
}

const Field = (props) => {
    return (
        <>
            <View style={{
                paddingRight: '25px', display: 'flex',
                flexDirection: 'row', color: '#333',
                borderBottom: '1px solid #eee',
                padding: 5
            }}>
                <Text style={{ fontFamily: 'Roboto', color: '#555', fontWeight: 'medium', fontSize: '12px' }}>
                    {props.fieldName}
                </Text>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'normal', fontSize: '12px' }}>
                    {props.data}
                </Text>
            </View>
        </>
    )
}

const Divider = (props) => {
    return (
        <View style={{ borderBottom: '1px', borderBottomColor: '#ccc', height: '1px' }} />
    )
}

const Title = (props) => {
    return (
        <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', padding: '7px', backgroundColor: '#eee', borderRadius: 10 }}>
            <Text style={{ fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'medium' }}>
                {props.title}
            </Text>
        </View>
    )
}

const Signature = (props) => {
    return (
        <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <View style={{ width: '100px', borderBottom: '1px', borderBottomStyle: 'dotted', borderBottomColor: 'grey', height: '1px' }} />
            <Text style={{ paddingTop: '5px', fontFamily: 'Roboto', fontSize: '10px' }}>{props.text}</Text>
        </View>
    )
}

export function RenderMedicalRecordPDF(configuration, memberMedicalRecord, language, agency) {
    let MedicalRecordPDF = (
        <Document>
            <Page size="A4" style={{ padding: '20px', border: '30px solid #eee' }} wrap>

                <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', width: '100%', height: '60px' }}>
                    <View style={{ flexDirection: 'row', width: 'auto', justifyContent: 'center', height: '100%' }}>
                        <View style={{ flexDirection: 'row', alignContent: 'left', width: '25%' }}>
                            <Image src={{ uri: (configuration.logo), method: 'GET', headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' }, body: '' }} />
                        </View>
                        <View style={{
                            backgroundColor: '#eee', borderRadius: 10., justifyContent: 'center',
                            width: '50%',
                            alignItems: 'center', alignContent: 'center'
                        }}>
                            <Text style={{ fontFamily: 'Roboto Black', color: '#333', fontSize: '24px', }}>Ficha Médica</Text>
                        </View>

                        <View style={{ flexDirection: 'row', alignContent: 'left', width: '25%' }}>
                            {agency === 'flecha' &&
                                <Image src={assistcard} />
                            }
                        </View>

                    </View>
                </View>

                <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', padding: '20px', paddingHorizontal: '40px' }}>
                    <Text style={{ fontFamily: 'Roboto Light', fontSize: '10px' }}>
                        {configuration.medicalHeader}
                    </Text>
                </View>

                <Divider color={'#ccc'} />

                <View style={styles.dataMainContainer}>

                    <Title title='Datos del Pasajero' />
                    <View style={styles.dataContainer}>
                        <Field style={styles.row} fieldName='Nombre y Apellido: ' data={(memberMedicalRecord.firstname + ' ' + memberMedicalRecord.lastname)} />
                        <Field style={styles.row} fieldName={language !== 'esCH' ? 'DNI: ' : 'RUT: '} data={memberMedicalRecord.identification} />
                        <Field style={styles.row} fieldName='Fecha de nacimiento: ' data={getDate(memberMedicalRecord.birthday)} />
                        <Field style={styles.row} fieldName='Dirección: ' data={memberMedicalRecord.address} />
                        <Field style={styles.row} fieldName='Ciudad: ' data={memberMedicalRecord.city} />
                        <Field style={styles.row} fieldName='Teléfono: ' data={memberMedicalRecord.cellphone} />
                        <Field style={styles.row} fieldName='Email: ' data={memberMedicalRecord.email} />
                        <Field style={styles.row} fieldName='Grupo Sanguineo: ' data={memberMedicalRecord.bloodType} />
                        {language !== 'esCH' &&
                            <Field style={styles.row} fieldName='Factor: ' data={memberMedicalRecord.bloodFactor} />
                        }
                    </View>

                    <Title title='Datos del Tutor' />
                    <View style={styles.dataContainer}>
                        <Field style={styles.row} fieldName='Nombre y Apellido: ' data={(memberMedicalRecord.tutorFirstname + ' ' + memberMedicalRecord.tutorLastname)} />
                        <Field style={styles.row} fieldName='Teléfono: ' data={memberMedicalRecord.tutorCellphone} />
                        <Field style={styles.row} fieldName='Dirección: ' data={memberMedicalRecord.tutorAddress} />
                    </View>

                    {memberMedicalRecord.attributes && memberMedicalRecord.attributes.length > 0 &&
                        <>
                            <Title style={{ borderBottom: '1px solid #ccc' }} title='Datos extra' />
                            <View style={styles.dataExtraContainer}>
                                {memberMedicalRecord.attributes.map((field, idx) => {
                                    return (
                                        <Field style={styles.row} key={idx} fieldName={`${field.label}: `} data={field.value} />
                                    )
                                })}
                            </View>
                        </>
                    }

                    <Title title='Preguntas' />
                    {memberMedicalRecord.questions &&
                        <View style={{
                            paddingBottom: '5px',
                            color: '#333',
                            padding: 20,
                            borderBottom: '1px solid #ccc'
                        }}>
                            {memberMedicalRecord.questions.map((question, index) => (
                                <View key={index} style={{
                                    paddingRight: '25px', display: 'flex',
                                    flexDirection: 'row', color: '#333',
                                    borderBottom: '1px solid #eee',
                                    padding: 5
                                }}>
                                    <Text style={{ fontFamily: 'Roboto', fontSize: '12px', borderBottom: '#ccc solid 1px' }}>{((index + 1) + '- ' + question.question)} : <span style={question.answer !== 'NO' && { color: 'red', fontWeight: 'bold' }}>{question.answer}</span></Text>
                                    {(question.description !== '' && question.description !== '-') && <Text style={{ fontFamily: 'Roboto', fontSize: '12px' }}>{question.description}</Text>}
                                </View>
                            ))}
                        </View>}

                    <View>
                        <Text style={{ fontFamily: 'Roboto Black', paddingTop: 20, fontSize: '12px' }}>
                            Observaciones:
                        </Text>
                        <Text style={{ fontFamily: 'Roboto', fontSize: '12px' }}>
                            {memberMedicalRecord.observations}
                        </Text>
                    </View>

                </View>

                <View wrap={false} style={{ paddingTop: '20px', paddingLeft: '25px', paddingRight: '25px' }}>
                    <Text>
                        {htmlParser(configuration.terms)}
                    </Text>
                </View>

                <View wrap={false}>

                    <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', paddingTop: '20px', paddingLeft: '25px', paddingRight: '25px' }}>
                        <Text style={{ fontFamily: 'Roboto Medium', fontSize: '10px' }}>
                            {configuration.medicalFooter}
                        </Text>
                    </View>

                    <View style={{ paddingTop: '40px', paddingHorizontal: '70px', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Signature text={'Firma de Padre / Madre / Tutor'} />
                        <Signature text={'Firma del Pasajero'} />
                        <Signature text={'Firma del Médico'} />
                    </View>

                    <View style={{ paddingTop: '20px', paddingHorizontal: '70px', alignItems: 'center', alignContent: 'center', flexDirection: 'row' }}>
                        <Text style={{ fontSize: 12 }}>Fecha</Text>
                    </View>
                    <View style={{ paddingTop: '10px', paddingHorizontal: '70px', alignItems: 'center', alignContent: 'center', flexDirection: 'row' }}>
                        <Text style={{ fontSize: 12 }}>____ /____ /________</Text>
                    </View>
                </View>



            </Page>
        </Document>
    )

    //ReactDOM.render(pdfLoco, document.getElementById('root'))
    return MedicalRecordPDF
}