import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Button } from 'primereact/button'
import { LogoAccesBand } from 'components/Logo'
import Styles from "./MedicalRecord.module.scss"
import { accessBandAPI } from "api/access-band-api"
import { FullScreenLoader } from 'components/FullScreenLoader'
import { RenderMedicalRecordPDF } from 'service/MedicalRecordPDF'
import { pdf } from '@react-pdf/renderer'
import * as download from 'downloadjs'
import { useParams } from 'react-router'
import Tilt from 'react-tilt'
import medicalRecordSplash from 'assets/images/informe-medico.png'
import finishMedicalRecordSplash from 'assets/images/finish.png'
import * as Yup from 'yup';
import { CircularProgress, Step, StepLabel, Stepper, Tooltip, Typography } from '@mui/material'
import PaxDataForm from './PaxDataForm'
import medicalRecordFormModel from './validationschema/medicalRecordFormModel'
import validationschema from './validationschema/validationschema'
import DiseasesForm from './DiseasesForm'
import ConfirmationForm from './ConfirmationForm'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast'
import { FileUpload } from 'primereact/fileupload'



const MedicalRecord = () => {
    const { t, i18n } = useTranslation();

    const [paxDefined, setPaxDefined] = React.useState(undefined)
    const [loading, setLoading] = React.useState(false)
    const params = useParams()
    const [submited, setSubmited] = React.useState(false)
    const [memberMedicalRecord, setMemberMedicalRecord] = React.useState(undefined)
    const [originalMedicalRecord, setOriginalMedicalRecord] = React.useState(undefined)

    const [paxNotFound, setPaxNotFound] = React.useState(false)
    const [configurations, setConfigurations] = React.useState(undefined)
    const [editingMedicalRecord, setEditingMedicalRecord] = React.useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const mediaMatch = window.matchMedia('(min-width: 800px)');
    const [matches, setMatches] = useState(mediaMatch.matches);

    //stepper form
    const steps = ['Datos del Pasajero', 'Datos de Salud', 'Confirmación/Generación de PDF'];
    const { formId, formField } = medicalRecordFormModel;
    const [activeStep, setActiveStep] = useState(0);
    const [formWithExtraFields, setFormWithExtraFields] = useState()
    const currentValidationSchema = validationschema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const formikRef = useRef(null);
    const [files, setFiles] = React.useState([]);
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const toast = useRef(null)
    const fileUploadRef = React.createRef();

    const _renderStepContent = (step) => {
        switch (step) {
            case 0:
                return formWithExtraFields && <PaxDataForm formField={formWithExtraFields} />;
            case 1:
                return <DiseasesForm questions={memberMedicalRecord.questions} />;
            case 2:
                return <ConfirmationForm formField={formField} medicalData={formikRef.current.values} />
            default:
                return <div>Not Found</div>;
        }
    }

    React.useEffect(() => {
        getConfigurations(params.agency)
        //getExtraFields()
    }, [])

    const s3Path = process.env.REACT_APP_AMZ_URL


    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    const styles = {
        container: isRowBased => ({
            display: 'flex',
            flexDirection: isRowBased ? 'row' : 'column',
            justifyContent: 'space-around'
        }),
        splashHidden: hidden => ({
            display: hidden ? "block" : "none",
        }),
    };

    const getConfigurations = (agency) => {
        accessBandAPI.configuration.getPublicConfigurations(agency).then(response => {
            if (response) {
                setConfigurations(response.data)
                i18n.changeLanguage(response.data.language);
            }
        })
    }
    const [loadDownload, setLoadDownload] = useState(false)
    const downloadPDF = () => {
        setLoadDownload(true)
        pdf(RenderMedicalRecordPDF(configurations, memberMedicalRecord, i18n.language, params.agency)).toBlob().then((blob) => {
            download(blob, `${('Ficha médica de ' + memberMedicalRecord.firstname + ' ' + memberMedicalRecord.lastname)}.pdf`)
        }).then(c => {
            setLoadDownload(false)

        })
    }




    const uploadFiles = async (files) => {
        setFileUploadLoader(true)
        try {
            Array.from(files).forEach(async (file) => {
                let data = await accessBandAPI.medicalRecord.uploadAttachMedicalRecord(memberMedicalRecord.id, file, params.agency)
                if (data.data) {
                    const medical = originalMedicalRecord
                    if (medical.files == undefined) medical.files = []
                    medical.files.push(data.data)
                    setOriginalMedicalRecord({ ...originalMedicalRecord, ...medical })
                    toast.current.show({ severity: 'info', summary: 'Confirmado', detail: 'Archivo Subido exitosamente', life: 3000 });
                    //setFileUploadLoader(false)
                }
            })
            setFileUploadLoader(false)
            fileUploadRef.current.clear();
            setFiles([])

        } catch (error) {
            setFileUploadLoader(false)
        }
    }

    const fileUploadHandler = async (event) => {
        await uploadFiles(event.files)
    };

    const UploadedFilesTable = () => {
        const [files, setFiles] = React.useState(originalMedicalRecord && originalMedicalRecord.files);

        const handleRemoveFile = (index) => {
            const medical = originalMedicalRecord;
            medical.files.splice(index, 1);
            setOriginalMedicalRecord({ ...originalMedicalRecord, ...medical });
            // API call to remove file
            medical.birthday = moment(medical.birthday).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            delete medical['acceptAndConfirm']

            accessBandAPI.medicalRecord.editMedicalRecord(params.agency, medical).then(response => {
                if (response)
                    toast.current.show({ severity: 'info', summary: 'Confirmado', detail: 'eliminado', life: 3000 });

            })
        };

        return (
            <div style={{ height: 250, overflowY: 'auto' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ zIndex: '99', position: 'sticky', top: 0, backgroundColor: '#f0f0f0' }}>
                        <tr>
                            <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>{`${fileUploadLoader ? 'Subiendo archivos...' : 'Archivos subidos'}`}</th>
                            <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files &&
                            files.map((file, index) => (
                                <tr key={index}>
                                    <td style={{ padding: 10, borderBottom: '1px solid #ddd' }}>
                                        <a href={`${s3Path}/${file.url}`} target="_blank">
                                            {file.name}
                                        </a>
                                    </td>
                                    <td style={{ padding: 10, borderBottom: '1px solid #ddd' }}>
                                        <Tooltip placement='top' title='Eliminar archivo'>
                                            <Button
                                                style={{ margin: 4 }}
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                <i className="pi pi-trash" />
                                            </Button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const upload = () => {
        return (
            <div>
                <h5 style={{ textAlign: 'center', color: '#777', fontWeight: 800 }}>Subir Ficha Médica firmada (Sólo PDF, JPG, PNG) ó arrastre para cargar</h5>
                <FileUpload
                    ref={fileUploadRef}
                    name="file"
                    url=""
                    mode="advanced"
                    customUpload
                    multiple={true}
                    accept=".pdf,.jpg,.jpeg,.png"
                    maxFileSize={1000000}
                    //onSelect={selectHandler}
                    uploadHandler={fileUploadHandler}
                    cancelHandler={() => setFiles([])}
                    style={{ width: '100%' }}
                    chooseLabel={t('choose_file')}
                    uploadLabel={t('upload_file')}
                    cancelLabel={t('cancel')}
                />
                <hr />
                <UploadedFilesTable />
            </div>
        );
    };

    async function getMedicalRecord(identification, contract) {
        setLoading(true)
        accessBandAPI.medicalRecord.getMedicalRecord(params.agency, contract, identification).then(response => {
            if (!response.data || response.data.msg) {
                setErrorMessage(response.data.msg || 'Error inesperado')
                setPaxNotFound(true)
                setLoading(false)
            }
            else {
                // Chequeo por el tipo de sangre porque no tengo otra forma
                // de saber si la ficha está completa o no
                if (response.data.bloodType !== '')
                    setEditingMedicalRecord(true)
                setPaxNotFound(false)
                response.data.acceptAndConfirm = false
                response.data.birthday = moment(response.data.birthday).format('yyyy-MM-DD')

                const extraFields = response.data.attributes ? response.data.attributes.map(item => ({ [item.name]: { name: item.name, label: item.label, type: item.type, requiredErrorMsg: item.requiredMsg } })) : [];

                //extra fields from database
                const extraFieldsObject = extraFields.reduce((accumuSFr, value) => {
                    return { ...accumuSFr, ...{ [Object.keys(value)]: Object.values(value)[0] } };
                }, {});

                setFormWithExtraFields({ ...formField, ...extraFieldsObject });


                //we get the values of the attributes of dynamic fields
                const rawAttributes = response.data.attributes ? response.data.attributes.map(it => {
                    return (
                        { [it.name]: it.value }
                    )
                }) : []

                const extraFieldsObjectWithValues = rawAttributes.reduce((accumuSFr, value) => {
                    return { ...accumuSFr, ...{ [Object.keys(value)]: Object.values(value)[0] } };
                }, {});

                setOriginalMedicalRecord(response.data)
                setMemberMedicalRecord({
                    ...response.data, ...extraFieldsObjectWithValues
                });

                setPaxDefined(true)
                setLoading(false)
            }
        })
    }

    async function saveMedicalRecord(medicalRecord) {
        if (editingMedicalRecord) {
            accessBandAPI.medicalRecord.editMedicalRecord(params.agency, medicalRecord).then(response => {
                if (response)
                    setSubmited(true)
            })
        } else {
            accessBandAPI.medicalRecord.saveMedicalRecord(params.agency, medicalRecord).then(response => {
                if (response)
                    setSubmited(true)
            })
        }
    }

    async function _submitForm(values, actions) {
        let copiedValues = { ...values }

        //preprocess data in order to comply with medical-record model in backend
        if (copiedValues.attributes && copiedValues.attributes.length > 0) {
            copiedValues.attributes.forEach(item => {
                if (item.name === Object.entries(copiedValues).find(it => it[0] === item.name)[0]) {
                    item.value = Object.entries(copiedValues).find(it => it[0] === item.name)[1];
                    delete copiedValues[item.name];
                }
            })
        }

        //hasalert tiene que ir en falso y el backend lo pone en verdadero si la ficha tiene alertas
        setMemberMedicalRecord(copiedValues)
        copiedValues.birthday = moment(copiedValues.birthday).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        delete copiedValues['acceptAndConfirm']
        saveMedicalRecord({ ...copiedValues, hasalert: false })
        actions.setSubmitting(false)

        setActiveStep(activeStep + 1);

    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            if (values.attributes == undefined) values.attributes = []
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }


    const CustomErrorSpan = (props) => {
        return (
            <span style={{ color: 'red' }}>{props.msg}</span>
        )
    }


    if (submited) {
        return (
            <div className={Styles.container}>
                <div style={styles.container(matches)} className={`${Styles.wrapper} ${Styles.finalStep}`}>
                    <div style={styles.splashHidden(matches)}>
                        <Tilt className="Tilt" >
                            <div className="Tilt-inner">
                                <img style={{ maxWidth: '400px' }} src={finishMedicalRecordSplash} alt="IMG" />
                            </div>
                        </Tilt>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '10px' }}>
                        <Typography marginBottom={'10px'} component="h1" variant="h4" align="center" style={{ color: '#666' }}>¡Felicitaciones!</Typography>
                        <Typography marginBottom={'10px'} component="h1" variant="h5" align="center" style={{ color: '#666' }}>Has completado la ficha correctamente, no olvides descargar el PDF</Typography>

                        <Button
                            disabled={loadDownload}
                            style={{ alignItems: 'center', fontSize: 20, justifyContent: 'center', marginLeft: 30, marginRight: 30 }}
                            className="p-button-rounded p-button-danger"
                            onClick={() => downloadPDF()}><i className="pi pi-file-pdf p-mr-2" />
                            {!loadDownload ? 'Descargar ficha médica' : 'Espere...'}</Button>
                    </div>
                </div>
            </div>
        )
    }

    else if (paxDefined && memberMedicalRecord) {
        if (!paxNotFound && editingMedicalRecord) {

            return (
                <div className={Styles.container}>
                    <Toast baseZIndex={1200} ref={toast} />

                    <div className={Styles.medicalWrapper}>
                        <div className={Styles.medicalRecordHeader}>
                            {configurations ?
                                <div style={{ paddingBottom: '10px' }}>
                                    <img src={configurations.logo} alt="Logo" style={{ maxWidth: '70px' }} />
                                </div>
                                : <LogoAccesBand size='30' />
                            }
                            <h4 style={{ fontWeight: 800 }}>Ficha Médica de {memberMedicalRecord.lastname + ' ' + memberMedicalRecord.firstname}</h4>
                        </div>
                        <div className={Styles.medicalRecordHeader}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                                <div style={{ padding: 20, flexBasis: '70%' }}>
                                    {upload()}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', flexBasis: '50%' }}>
                                    <Button
                                        style={{
                                            alignItems: 'center', color: '#fff', backgroundSize: 'cover',
                                            borderRadius: 0, height: 350,
                                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', // added text shadow
                                            backgroundImage: 'url(https://www.recordnations.com/wp-content/uploads/2023/10/bigstock-Close-Up-Blank-Medical-Record-448416778-e1697645040714.webp)',
                                            backgroundSize: 'cover',
                                            fontSize: 40, justifyContent: 'center', marginLeft: 30, marginRight: 30
                                        }}

                                        onClick={() => setPaxNotFound(true)}>Click para editar la ficha médica</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={Styles.container}>
                    <div className={Styles.medicalWrapper}>
                        <div className={Styles.medicalRecordHeader}>
                            {configurations ?
                                <div style={{ paddingBottom: '10px' }}>
                                    <img src={configurations.logo} alt="Logo" style={{ maxWidth: '70px' }} />
                                </div>
                                : <LogoAccesBand size='30' />
                            }


                        </div>
                        <React.Fragment>
                            <Typography marginBottom={'10px'} component="h1" variant="h4" align="center">
                                {isLastStep ? (submited ? 'Felicitaciones! Ha enviado la ficha médica correctamente' : 'Por favor, confirme que los datos son correctos.') : 'Por favor, complete los datos de la ficha médica'}
                            </Typography>
                            <Stepper className={Styles.stepperWrapper} activeStep={activeStep}>
                                {steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <React.Fragment>
                                {activeStep === steps.length ? (
                                    <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                                        <CircularProgress size={40}
                                        />
                                    </div>
                                ) : (
                                    <Formik
                                        innerRef={formikRef}
                                        initialValues={memberMedicalRecord}
                                        validationSchema={currentValidationSchema}
                                        onSubmit={_handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form id={formId}>
                                                {_renderStepContent(activeStep)}
                                                <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between' }} >
                                                    <div className='mt-5' style={{ justifyContent: 'start' }}>
                                                        {activeStep !== 0 && (
                                                            <Button type='button' onClick={_handleBack} >
                                                                Anterior
                                                            </Button>
                                                        )}
                                                    </div>
                                                    <div className='mt-5' style={{ justifyContent: 'end' }}>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            {isLastStep ? 'Confirmar y Enviar' : 'Siguiente'}
                                                        </Button>
                                                        {isSubmitting && (
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </React.Fragment>
                        </React.Fragment>

                    </div>
                </div >
            )
        }
    } else {
        return (
            < >
                {loading
                    ? <FullScreenLoader />
                    :
                    <div className={Styles.container}>
                        <div style={styles.container(matches)} className={Styles.wrapper}>
                            <div style={styles.splashHidden(matches)}>
                                <Tilt className="Tilt" >
                                    <div className="Tilt-inner" style={{ marginTop: 100, width: 400 }}>
                                        <img width={300} src={medicalRecordSplash} alt="IMG" />
                                    </div>
                                </Tilt>
                            </div>
                            <Formik
                                initialValues={{
                                    identification: '',
                                    contract: ''
                                }}

                                onSubmit={async (values, { setSubmitting }) => {
                                    getMedicalRecord(values.identification.trim().replace(/[^a-zA-Z0-9]/g, ''), values.contract.trim().replace(/[^a-zA-Z0-9]/g, ''))
                                    setSubmitting(false)
                                }}

                                validationSchema={
                                    Yup.object({
                                        identification: Yup.string().required(t('message_dni')),
                                        contract: Yup.string().required('Ingrese el código de contrato'),
                                    })
                                }
                            >

                                {({ errors, touched, submitForm }) => (
                                    <Form>
                                        {configurations ?
                                            <div style={{ paddingBottom: '5px', textAlign: 'center' }}>
                                                <img src={configurations.logo} alt="Logo" style={{ maxWidth: '100px' }} />
                                            </div>
                                            : <LogoAccesBand size='20' />
                                        }
                                        {configurations &&
                                            <h3 style={{ textAlign: 'center', color: '#888' }}>{configurations.description}</h3>
                                        }
                                        <span style={{ color: '#333' }}>Ingrese para completar ficha médica</span>
                                        <div className={`${Styles.inputWrapper}`} >
                                            <Field className={`${Styles.input100}  ${errors.identification && touched.identification && Styles.error}`} type="text" name="identification" placeholder={i18n.language === 'esCH' ? 'Ingrese RUT' : 'Ingrese DNI'} />
                                        </div>
                                        <ErrorMessage name="identification" render={msg => <CustomErrorSpan msg={msg} />} />
                                        <div className={`${Styles.inputWrapper}`} >
                                            <Field className={`${Styles.input100} ${errors.contract && touched.contract && Styles.error}`} type="text" name="contract" placeholder="Contrato*" />
                                        </div>
                                        <ErrorMessage name="contract" render={msg => <CustomErrorSpan msg={msg} />} />
                                        <div className={`${Styles.inputWrapper}`} >
                                            <button type='button' onClick={() => submitForm()} className={`${Styles.formBtn}`}>
                                                Ingreso
                                            </button>
                                        </div>
                                        <LogoAccesBand size='40' />
                                        <br />
                                        <div hidden={!paxNotFound} className={`${Styles.errorBar}`}>
                                            {`Error: ${errorMessage}`}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default MedicalRecord
